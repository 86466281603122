<template>
	<form class="main-wrapper mt-4">
		<CRow class="mb-5">
			<CCol md="12">
				<h4>General info</h4>
				<CInput
					v-model.trim="$v.localName.$model"
					:is-valid="!$v.localName.$error && null"
					:invalid-feedback="$t('global.error.required')"
					class="mt-4"
					label="Plan name*"
					placeholder="E.g. Apple KTC forever plan"
				/>
			</CCol>
			<CCol md="12">
				<hr class="mb-4">
			</CCol>

			<CCol md="12">
				<h4>Product ID</h4>
				<div class="typo-body-2 color-black-45">
					Set Product ID for this cc forever plan to let product to absorb the interest
				</div>
			</CCol>
			<CCol md="4">
				<div class="form-group mt-3">
					<CInput
						v-model.trim="$v.localKTCProductId.$model"
						:is-valid="!$v.localKTCProductId.$error && null"
						:invalid-feedback="ktcProductIdErrorMessage"
						label="Product ID*"
						placeholder="E.g. V012"
					/>
				</div>
			</CCol>
			<CCol md="12">
				<hr class="mb-4">
			</CCol>

			<CCol md="12">
				<h4>Plan info</h4>
				<div class="typo-body-2 color-black-45">
					Currently, We propose to reduce the price according to the rate multiplied by the price of the product, for example X% discount by paying 200%(Rate) Points from the price paid.
				</div>
			</CCol>
			<CCol md="6">
				<div class="form-group mt-3">
					<BaseInputNumber
						v-model="$v.localRate.$model"
						:is-valid="!$v.localRate.$error"
						:invalid-feedback="rateErrorMessage"
						label="Point ratio*"
						placeholder="1"
						append-text="%"
					/>
				</div>
			</CCol>
			<CCol md="6">
				<div class="form-group mt-3">
					<BaseInputNumber
						v-model="$v.localDiscountPercent.$model"
						:is-valid="!$v.localDiscountPercent.$error"
						:invalid-feedback="discountPercentErrorMessage"
						label="Discount percent*"
						placeholder="0"
						append-text="%"
					/>
				</div>
			</CCol>
			<CCol md="12">
				<hr class="mb-4">
			</CCol>
		</CRow>

		<BaseActionPanelStickyFooter
			:disabled-confirm="isSubmitting"
			:remove-text="isEdit ? 'Remove plan' : null"
			:is-edit="isEdit"
			content-class="main-wrapper"
			@onRemove="isEdit ? $refs['modal-remove'].open() : null"
			@onConfirm="handleSubmit"
			@onCancel="redirectToPaymentSetting"
		/>
		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deletePlan.bind(null, { methodId: methodId, planId: this.$route.params.planId })"
			title="Remove this forever plan?"
			description="By removing this, the forever plan and condition will be removed from the system and will no longer available for customers on the Studio7 website."
			@onSuccess="redirectToPaymentSetting"
		/>
	</form>
</template>

<script>
import { mapActions } from 'vuex';

import { required, maxLength, minLength, minValue, maxValue } from 'vuelidate/lib/validators';

import { parseNumber } from '../assets/js/helpers';

import { ROUTE_NAME } from '../enums/route';

export default {
	name: 'FormKTCForeverPlan',

	validations() {
		return {
			localName: {
				required,
			},
			localRate: {
				required,
				minValue: minValue(1),
				maxValue: maxValue(100),
			},
			localDiscountPercent: {
				required,
				minValue: minValue(0),
				maxValue: maxValue(100),
			},
			localKTCProductId: {
				required,
				maxLength: maxLength(4),
				minLength: minLength(4),
			},
		};
	},

	props: {
		isSubmitting: {
			type: Boolean,
			default: false,
		},
		isEdit: {
			type: Boolean,
			default: false,
		},
		methodId: {
			type: [String, Number],
			default: null,
		},
		groupId: {
			type: [String, Number],
			default: null,
		},
		rate: {
			type: Number,
			default: null,
		},
		name: {
			type: String,
			default: null,
		},
		ktcProductId: {
			type: String,
			default: null,
		},
		discountPercent: {
			type: Number,
			default: null,
		},
	},

	data() {
		return {
			// form
			localName: null,
			localRate: null,
			localDiscountPercent: null,
			localKTCProductId: null,
		};
	},

	computed: {
		ktcProductIdErrorMessage() {
			if (!this.$v.localKTCProductId.maxLength || !this.$v.localKTCProductId.minLength) {
				return `Must have 4 character`;
			} else if (!this.$v.localKTCProductId.required) {
				return this.$t('global.error.required');
			}

			return null;
		},

		rateErrorMessage() {
			if (!this.$v.localRate.minValue) {
				return 'Rate must be greater than 0';
			} else if (!this.$v.localRate.maxValue) {
				return 'Rate percent must be less than or equal to 100';
			} else if (!this.$v.localRate.required) {
				return this.$t('global.error.required');
			}

			return null;
		},

		discountPercentErrorMessage() {
			if (!this.$v.localDiscountPercent.minValue) {
				return 'Discount percent must be greater than or equal to 0';
			} else if (!this.$v.localDiscountPercent.maxValue) {
				return 'Discount percent must be less than or equal to 100';
			} else if (!this.$v.localDiscountPercent.required) {
				return this.$t('global.error.required');
			}

			return null;
		},
	},

	mounted() {
		if (this.isEdit) {
			this.localName = this.name;
			this.localRate = this.rate;
			this.localDiscountPercent = this.discountPercent;
			this.localKTCProductId = this.ktcProductId;
		}
	},
	methods: {
		...mapActions({
			deletePlan: 'payments/deleteCCForeverPlan',
			showToast: 'toast/showToast',
		}),

		handleSubmit() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

			const params = {
				plan_name: this.localName,
				rate: parseNumber(this.localRate),
				discount_percent: parseNumber(this.localDiscountPercent),
				ktc_product_id: this.localKTCProductId,
			};

			this.$emit('onSubmit', params);
		},

		redirectToPaymentSetting() {
			this.$router.push({
				name: ROUTE_NAME.PAYMENT_METHOD_SETTING,
				params: { id: this.groupId, methodId: this.methodId },
			});
		},

		handleRemovePlan() {
			this.redirectToPaymentSetting();
		},
	},
};
</script>

<style lang="scss" scoped>
	.icon-edit-plan {
		@include typo-body-2;

		cursor: pointer;
		color: $color-black-45;
		font-weight: 500;

		&:hover {
			color: $color-orange;
		}
	}
</style>
