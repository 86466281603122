<template>
	<FormKTCForeverPlan
		:is-submitting="plan.create.isCreating"
		:group-id="$route.params.id"
		:method-id="$route.params.methodId"
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FormKTCForeverPlan from '@/components/FormKTCForeverPlan.vue';
import { ROUTE_NAME } from '../enums/route';

export default {
	name: 'KTCForeverPlanCreate',

	components: {
		FormKTCForeverPlan,
	},

	computed: {
		...mapState('payments', {
			plan: 'ccForeverPlan',
		}),
	},

	methods: {
		...mapActions({
			createPlan: 'payments/createCCForeverPlan',
		}),

		async handleSubmit(params) {
			const methodId = this.$route.params.methodId;
			const groupId = this.$route.params.id;

			try {
				await this.createPlan({
					methodId,
					params,
				});

				this.$router.push({
					name: ROUTE_NAME.PAYMENT_METHOD_SETTING,
					params: { id: groupId, methodId },
				});
			} catch {
				// error
			}
		},
	},
};
</script>
